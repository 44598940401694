<template>
  <div :class="{ [$route.meta.viewClass]: $route.meta.viewClass }">
    <v-banner
      v-show="showBanner"
      class="router-view__banner"
      :class="{ 'router-view__banner--navigation-bar': navigationBar }"
      color="#00A7C7"
      icon="mdi-cellphone-arrow-down-variant"
      :lines="mobileViewport ? 'three' : 'one'"
      elevation="1"
    >
      <template #prepend>
        <v-avatar />
      </template>

      <v-banner-text>
        Now you can download the app.
      </v-banner-text>

      <v-banner-actions>
        <v-btn
          size="x-large"
          @click="installPWA"
        >
          Download
        </v-btn>
      </v-banner-actions>
    </v-banner>
    <v-app>
      <NavigationComponent v-if="navigationBar" />
      <div
        class="container"
        :class="{ 'container--website': !navigationBar }"
      >
        <NotificationBar />
        <ErrorPage>
          <ErrorBoundary>
            <router-view :key="$route.path" />
          </ErrorBoundary>
        </ErrorPage>
      </div>
    </v-app>

    <div id="modal" />
  </div>
</template>

<script src="./routerView.js" />

<style lang="scss">
  .router-view {
    &__banner {
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      .v-btn {
        font-size: 1.8rem;
      }
      .v-banner-text {
        overflow: visible;
        line-height: normal;
        @include media-breakpoint-up(md) {
          line-height: revert-layer;
        }
      }
      &--navigation-bar {
        margin-top: 7rem;
      }
    }
  }
  .container {
    margin-top: 10rem;
    max-width: 100%;
    padding: 0 2.4rem 3.2rem;

    @include media-breakpoint-up(lg) {
      max-width: 102.4rem;
      padding: 0 7.2rem 3.2rem 10rem;
    }

    @media (min-width: 1200px) {
      padding: 0 3.2rem 3.2rem;
    }

    &--website {
      min-height: 100vh;
      margin-top: 0;
    }
  }
</style>
