import pendoMixin from '@/mixins/pendoMixin';

import {
  VApp,
  VBanner,
  VAvatar,
  VBannerText,
  VBannerActions,
  VBtn,
} from 'vuetify/components';

import ErrorBoundary from 'Components/errorBoundary/errorBoundary';
import ErrorPage from 'Components/errorPage/errorPage';
import NavigationComponent from 'Components/navigationComponent/NavigationComponent.vue';
import NotificationBar from 'Components/notifications/notificationBar/NotificationBar.vue';

export default {
  components: {
    ErrorBoundary,
    ErrorPage,
    NotificationBar,
    NavigationComponent,
    VApp,
    VBanner,
    VAvatar,
    VBannerText,
    VBannerActions,
    VBtn,
  },

  mixins: [pendoMixin],

  data() {
    return {
      deferredPrompt: null,
      showBanner: false,
    };
  },

  computed: {
    navigationBar() { return this.$route.meta.navigationBar; },
    mobileViewport() {
      console.log('sm', this.$vuetify?.display.smAndDown);
      return this.$vuetify?.display.smAndDown;
    },
  },

  created() {
    window.addEventListener('beforeinstallprompt', this.setPWAConfig);
    this.initializePendo();
  },

  methods: {
    setPWAConfig(e) {
      e.preventDefault();
      this.deferredPrompt = e;
      console.log('beforeinstallprompt fired');
      this.showBanner = true;
    },
    async installPWA() {
      this.showBanner = false;
      // Show the install prompt
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();

        // Wait for the user’s response to the prompt
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }

        // Clear the deferred prompt variable since it's no longer needed
        this.deferredPrompt = null;
        window.removeEventListener('beforeinstallprompt', this.setPWAConfig);
      }
    },
  },

};
